// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-cards-hometopcards-js": () => import("./../../../src/pages/components/cards/Hometopcards.js" /* webpackChunkName: "component---src-pages-components-cards-hometopcards-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/components/Footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-home-page-concentrate-js": () => import("./../../../src/pages/components/HomePage/Concentrate.js" /* webpackChunkName: "component---src-pages-components-home-page-concentrate-js" */),
  "component---src-pages-components-home-page-div-seperator-js": () => import("./../../../src/pages/components/HomePage/DivSeperator.js" /* webpackChunkName: "component---src-pages-components-home-page-div-seperator-js" */),
  "component---src-pages-components-home-page-homehero-js": () => import("./../../../src/pages/components/HomePage/Homehero.js" /* webpackChunkName: "component---src-pages-components-home-page-homehero-js" */),
  "component---src-pages-components-home-page-lightsection-js": () => import("./../../../src/pages/components/HomePage/Lightsection.js" /* webpackChunkName: "component---src-pages-components-home-page-lightsection-js" */),
  "component---src-pages-components-home-page-smart-modules-box-js": () => import("./../../../src/pages/components/HomePage/SmartModulesBox.js" /* webpackChunkName: "component---src-pages-components-home-page-smart-modules-box-js" */),
  "component---src-pages-components-layout-js": () => import("./../../../src/pages/components/Layout.js" /* webpackChunkName: "component---src-pages-components-layout-js" */),
  "component---src-pages-components-nav-menu-js": () => import("./../../../src/pages/components/NavMenu.js" /* webpackChunkName: "component---src-pages-components-nav-menu-js" */),
  "component---src-pages-components-seo-js": () => import("./../../../src/pages/components/Seo.js" /* webpackChunkName: "component---src-pages-components-seo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-conditionsofuse-js": () => import("./../../../src/pages/legal/conditionsofuse.js" /* webpackChunkName: "component---src-pages-legal-conditionsofuse-js" */),
  "component---src-pages-legal-privacypolicy-js": () => import("./../../../src/pages/legal/privacypolicy.js" /* webpackChunkName: "component---src-pages-legal-privacypolicy-js" */),
  "component---src-pages-test-2-js": () => import("./../../../src/pages/test2.js" /* webpackChunkName: "component---src-pages-test-2-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

